import NetData from 'data/NetData';
import ConstantData from 'data/ConstantData';


let imgFunctions = {};



imgFunctions.GetImgBySizeProp = ( imgData, sizeToGet, propToGet ) => {

	if ( !imgData )
		return null;

	if ( sizeToGet === ConstantData.imgSizes.raw )
		return imgData[propToGet];

	if ( imgData.formats && imgData.formats[sizeToGet] )
		return imgData.formats[sizeToGet][propToGet];

	return imgData[propToGet];

};

imgFunctions.GetImgBySizeUri = ( imgData, sizeToGet, fallbackUri = null ) => {

	const uriReturned = imgFunctions.GetImgBySizeProp( imgData, sizeToGet, "url" );

	if ( !uriReturned )
		return fallbackUri;

	return NetData.imgUri( uriReturned );

};
imgFunctions.GetImgBySizeWidth = ( imgData, sizeToGet ) => {

	const returned = imgFunctions.GetImgBySizeProp( imgData, sizeToGet, "width" );

	if ( !returned )
		return null;

	return returned;

};
imgFunctions.GetImgBySizeHeight = ( imgData, sizeToGet ) => {

	const returned = imgFunctions.GetImgBySizeProp( imgData, sizeToGet, "height" );

	if ( !returned )
		return null;

	return returned;

};


imgFunctions.GetAllImgElmProps = ( imgData, sizeToGet ) => {

	let imgProps = {
		src: imgFunctions.GetImgBySizeUri( imgData, sizeToGet ),
		width: imgFunctions.GetImgBySizeWidth( imgData, sizeToGet ),
		height: imgFunctions.GetImgBySizeHeight( imgData, sizeToGet ),
	}

	return imgProps;
};



export default imgFunctions;