import React from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// FaArrowLeft,
} from "react-icons/fa";

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


import ThemeData from "../data/ThemeData";



// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";



const useStyles = createUseStyles(
	{
		header: props => ( {
			display: 'flex',
			alignItems: 'center',

			width: '100%',
			height: 100,

			backgroundColor: ThemeData.colours.primaryHeaderBg,

			zIndex: 2,
		} ),
		backButton: {
			paddingLeft: 40,

			cursor: 'pointer',

			color: ThemeData.colours.primaryFont,
			fontSize: 24,
			backgroundColor: ThemeData.colours.primaryBg,
		},
	},
	{
		name: "HeaderSimple",
	}
)



const HeaderSimple = ( { onBackClick, ...props } ) => {

	const classes = useStyles( props );

	return (
		<Link to={props.backLocation}>
			<div className={classes.header}>
				<div className={classes.backButton} onClick={onBackClick}>
					<FontAwesomeIcon icon={faArrowLeft} /> BACK
				</div>
			</div>
		</Link>
	);
}

HeaderSimple.defaultProps = {
	backgroundColor: ThemeData.colours.white50,
};



export default HeaderSimple;