import {
	// useMemo,
	useState,
} from 'react';

import {
	useQuery,
} from "@apollo/client";

// import createPersistedState from "use-persisted-state";

import QueryData from "data/QueryData";
// import ConstantData from "data/ConstantData";


// const useSiteFeatureTogglesState = createPersistedState( ConstantData.localStorageKeys.siteFeatureToggles );


const useSiteConfiguration = () => {

	// const [siteFeatureToggles, setSiteFeatureToggles] = useSiteFeatureTogglesState( [] );
	const [siteConfiguration, setSiteConfiguration] = useState( {
		data: null,
		dataStateIsLoaded: false,
	} );


	useQuery( QueryData.siteConfiguration, {
		onCompleted: data => {
			// console.log( 'scData', data );

			setSiteConfiguration( {
				...siteConfiguration,

				data: data.siteConfiguration,
				dataStateIsLoaded: true,
			} );
		}
	} );


	return siteConfiguration;


}


export default useSiteConfiguration;