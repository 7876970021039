import React, {
	// useState
} from "react";
import { createUseStyles } from "react-jss";


// import { useQuery, gql } from "@apollo/client";

// import {
// 	motion,
// } from "framer-motion";


// import Nav from "./Nav";
import ThemeData from "../data/ThemeData";


const useStyles = createUseStyles( {
	footer: {
		height: 100,
		display: props => props.hidden ? 'none' : '',
		backgroundColor: ThemeData.colours.primaryBgTransparent,

		borderTop: `1px ${ThemeData.colours.thinBorder} solid`,
	}
} );

const Footer = ( { children, ...props } ) => {
	
	// const [isOpen, toggle] = useState( false );
	const hidden = props.hidden
	const classes = useStyles({hidden});

	return (
		<footer className={classes.footer}>
			{/* <div className={classes.mainMenuButton} onClick={() => toggle( !isOpen )}>≡⚞ CLOSE MENU</div> */}
			{children}
		</footer>
	)

};


export default Footer;