import React, {
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


// import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';



// import ThemeData from "data/ThemeData";



const jsStyles = {
	// clearSavedHomesConfirmationDialogTitle: {
	// 	color: ThemeData.colours.primaryFont,
	// 	backgroundColor: ThemeData.colours.primaryBg,

	// 	textTransform: 'uppercase',

	// 	fontFamily: ThemeData.fonts.title,
	// 	fontSize: 20,
	// },
	// clearSavedHomesConfirmationDialogBody: {
	// 	color: ThemeData.colours.primaryFont,
	// 	backgroundColor: ThemeData.colours.primaryBg,

	// 	fontFamily: ThemeData.fonts.copy,
	// 	fontSize: 14,
	// },
	// clearSavedHomesConfirmationDialogButtons: {
	// 	color: ThemeData.colours.primaryFont,
	// 	backgroundColor: ThemeData.colours.primaryBg,

	// 	textTransform: 'uppercase',

	// 	fontFamily: ThemeData.fonts.title,
	// 	fontSize: 16,
	// },
};
const useStyles = createUseStyles(
	jsStyles,
	{ name: "DialogGeneric" }
)



const DialogGeneric = ( { children, data, className, ...props } ) => {

	const classes = useStyles();


	return (
		<Dialog
			open={props.isDialogOpen}
			onClose={props.setIsDialogOpenToClosed}
			aria-labelledby={props.ariaLabelledBy}
			aria-describedby={props.ariaDescribedBy}
			// aria-labelledby="confirm-dialog-title"
			// aria-describedby="confirm-dialog-description"
		>
			{children}
			{/* <DialogTitle
				id="confirm-dialog-title"
				sx={jsStyles.clearSavedHomesConfirmationDialogTitle}
			>
				{`Clear ${props.dataTypeToClearName} Confirmation`}
			</DialogTitle>
			<DialogContent
				sx={jsStyles.clearSavedHomesConfirmationDialogBody}
			>
				<DialogContentText
					id="confirm-dialog-description"
					sx={jsStyles.clearSavedHomesConfirmationDialogBody}
				>
					Are you sure you would like to clear your {props.dataTypeToClearName.toLowerCase()}?
				</DialogContentText>
			</DialogContent>
			<DialogActions
				sx={jsStyles.clearSavedHomesConfirmationDialogButtons}
			>
				<Button
					sx={jsStyles.clearSavedHomesConfirmationDialogButtons}
					onClick={props.setIsClearConfirmationDialogOpenToClosed}
				>
					Don't Clear
				</Button>
				<Button
					sx={jsStyles.clearSavedHomesConfirmationDialogButtons}
					onClick={() => {
						props.afterClearConfirmationPerformFunction();
						props.setIsClearConfirmationDialogOpenToClosed()
					}}
				>
					Clear {props.dataTypeToClearName}
				</Button>
			</DialogActions> */}
		</Dialog>
	);
}



export default DialogGeneric;