import {
	createUseStyles,
} from "react-jss";

import * as ReactDomServer from 'react-dom/server'

import ThemeData from "data/ThemeData";
import crossIcon from '../img/cross_icon.svg';

const useStyles = createUseStyles(
	{
		modalContainer: {
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,0.8)',
            width: '100%',
            height: '100vh',
            display: 'flex',
			overflow: 'hidden',
            zIndex: 9999,
            justifyContent: 'center'
        },
        borderWrapper: {
            maxWidth: 800,

			width: '100%',
			margin: '50px 0',

			background: 'rgba( 0, 0, 0, 0 )',
		},
		wrapper: {
			height: '85%',
            '@media (max-width: 1200px)': {
                height: '80%'
            },
            width: '800px',
            position: 'fixed',
            top: 'calc(var( --header-height ) + 25px)',
            left: 'calc((100vw - 800px) / 2)',

			background: ThemeData.colours.white,
			boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.33)',
			borderRadius: '5px',
			overflow: 'hidden'
		},
        header: {
			display: 'flex',
			width: '100%',
			height: 60,
			border: '1px solid #EEEDE6',
			alignItems: 'center',
            paddingTop: 20,
            paddingBottom: 10,
			'& > img': {
				alignSelf: 'flex-end',
				height: 14,
				width: 14,
				marginRight: 28,
				margin: 'auto 0',
				cursor: 'pointer'
			},
			'& > div': {
				alignSelf: 'center',
				flexGrow: '3',
				textAlign: 'center',
				paddingLeft: 42,
                fontSize: 18,
                fontFamily: ThemeData.fonts.title,
			    color: ThemeData.colours.primaryBg,
			}
		},
        text: {
            overflow: 'auto',
            height: 'calc(100% - 80px)',
            
            '&::-webkit-scrollbar':{
				display: 'none'
			},
        },
        title: {
            textAlign: 'center',
			fontFamily: ThemeData.fonts.title,
			color: ThemeData.colours.primaryBg,
			fontSize: 24,
			textTransform: 'uppercase',
			letterSpacing: '0.1em',
        },
        subheading: {	
            padding: '0px 8% 30px 8%',
            textAlign: 'left',
			fontFamily: ThemeData.fonts.title,
			color: ThemeData.colours.primaryBg,
			fontSize: 16,
            textTransform: 'uppercase',
			letterSpacing: '.05em',
		},
		description: {	
            padding: '0px 8% 30px 8%',
            textAlign: 'left',
			fontFamily: ThemeData.fonts.copy,
			color: ThemeData.colours.primaryBg,
			fontSize: 14,
			letterSpacing: '.05em',
		},
	},
	{
		name: "Policies",
	}
)


const PoliciesModal = ({...props}) => {

    const classes = useStyles();

    const handlePrivacyPolicyClick = (e) => {
        const element = e.target.closest('a')
        if (element && e.currentTarget.contains(element)) 
            {props.onClickFcn()
            props.setIsDialogOpenToClosed()}
    }
    return (
        props.isDialogOpen &&
            <div className={classes.modalContainer}>
                <div className={classes.borderWrapper}>
                    <div className={classes.wrapper}>
                        <div className={classes.header}>
                            <div>
                                Proxima<br/>
                                {props.data.title}
                            </div>
                            <img onClick={() => props.setIsDialogOpenToClosed()} src={crossIcon} alt="close" />
                        </div>
                        <div className={classes.text}>
                            <div 
                                className={classes.description} 
                                onClick={(e) => handlePrivacyPolicyClick(e)} 
                                dangerouslySetInnerHTML={
                                    { __html: typeof props.data.introduction === 'object' ? ReactDomServer.renderToString(props.data.introduction) : props.data.introduction}
                                } />
                            {props.data.paragraphs.map(text => (
                                <div>
                                    <div className={ReactDomServer.renderToString(text.subheading) !== "" && classes.subheading}>
                                        {text.subheading}
                                    </div>
                                    <div className={classes.description} onClick={(e) => handlePrivacyPolicyClick(e)} 
                                    dangerouslySetInnerHTML={
                                    { __html: typeof props.data.introduction === 'object' ? ReactDomServer.renderToString(text.description) : text.description}
                                } />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
    )
}


export default PoliciesModal;