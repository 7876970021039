import {
	createContext,
} from "react";

const UnitFcnContext = createContext( {
	selectedUnitId: null,
	setSelectedUnitId: null,

	isUnitDetailOpen: null,
	setIsUnitDetailOpen: null,
	toggleIsUnitDetailOpen: null,
} );

export default UnitFcnContext;