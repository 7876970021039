import {
	// useMemo,
	useState,
} from 'react';

import {
	useQuery,
} from "@apollo/client";

// import createPersistedState from "use-persisted-state";

import QueryData from "data/QueryData";
// import ConstantData from "data/ConstantData";


// const useSiteFeatureTogglesState = createPersistedState( ConstantData.localStorageKeys.siteFeatureToggles );


const useSiteFeatureToggles = () => {

	// const [siteFeatureToggles, setSiteFeatureToggles] = useSiteFeatureTogglesState( [] );
	const [siteFeatureToggles, setSiteFeatureToggles] = useState( {
		siteFeatureTogglesData: null,
		dataStateIsLoaded: false,
		isPricesOn: null,
	} );


	useQuery( QueryData.siteFeatureToggles, {
		onCompleted: data => {
			// console.log( 'sftData', data );

			setSiteFeatureToggles( {
				...siteFeatureToggles,

				siteFeatureTogglesData: data,
				dataStateIsLoaded: true,
				isPricesOn: data.siteFeatureToggle.prices,
			} );
		}
	} );


	return siteFeatureToggles;


}


export default useSiteFeatureToggles;