import createPersistedState from "use-persisted-state";

import ConstantData from "data/ConstantData";


const useRegistrationState = createPersistedState( ConstantData.localStorageKeys.registration );


const useRegistration = () => {

	const emptyRegistration = {
		fName: "",
		lName: "",
		email: "",
	};

	const [registration, setRegistration] = useRegistrationState( emptyRegistration );

	return {
		registration,
		setFirstName: fName => {
			setRegistration( {...registration, fName: fName } );
		},
		setLastName: lName => {
			setRegistration( {...registration, lName: lName } );
		},
		setEmail: email => {
			setRegistration( {...registration, email: email } );
		},
		clearRegistration: () => {
			setRegistration( { ...emptyRegistration } )
		},
	}

}


export default useRegistration;