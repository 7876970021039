import React, {

} from "react";

import {
	Link,
} from "react-router-dom";

import {
	createUseStyles,
} from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";
// import ConstantData from "data/ConstantData";



const useStyles = createUseStyles(
	{
		wrapper: {
			padding: '15px 0 40px 0',

			textAlign: 'left',

			...ThemeData.styles.linkColourRemoval,
		},
		title: {
			fontFamily: ThemeData.fonts.copy,
			fontSize: 20,
		},
		imageWrapper: {
			paddingTop: 30,
		},
		image: {
			maxHeight: 450,
			maxWidth: 600,
			aspectRatio: 4 / 3,
			objectFit: 'cover',
		},
	},
	{
		name: "TopMenuContentPage",
	}
)



const TopMenuContentPage = ( { dataContentPage, className, ...props } ) => {

	const classes = useStyles();


	const displayContent= (content) => {
		if( content.url.includes('.mp4') ){
			return (
				<video
					width="100%"
					// controls
					autoPlay
					muted
					loop>
					<source src={content.url} type="video/mp4"/>
				</video>
			)
		}

		return (
			<img
				className={classes.image}
				// TODO: Implement checks for in case medium image doesn't exist
				src={`${NetData.imgUri( ( dataContentPage.image[0].formats && dataContentPage.image[0].formats.medium && dataContentPage.image[0].formats.medium.url ) || dataContentPage.image[0].url )}`}
				alt={dataContentPage.image[0].alternativeText}
			/>
		)
	};


	// console.log( 'dcp', dataContentPage );


	return (
		<div className={`${classes.wrapper} ${className}`}>
			<Link
				to={dataContentPage.section.slug + '/' + dataContentPage.slug}
			>
				<div className={classes.title}>
					{dataContentPage.title}
				</div>
				<div className={classes.imageWrapper}>
					{dataContentPage.image && dataContentPage.image[0] &&
						displayContent(dataContentPage.image[0])
					}
				</div>
			</Link>
		</div>
	);
}



export default TopMenuContentPage;